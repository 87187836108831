<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <v-layout id="page-wrapper" justify-center>
    <v-flex xs12 sm12 md4 lg3>
      <login-form />
    </v-flex>
    <v-flex fill-height hidden-sm-and-down>
      <login-background logo="img/login.svg" image="img/login-background.jpg" />
    </v-flex>
  </v-layout>
</template>
<script>
import LoginForm from '@/components/login-form/LoginForm.vue';
import LoginBackground from '@/components/login-background/LoginBackground.vue';
import { URL_MATCHER } from '@/constants';

export default {
  components: {
    LoginForm,
    LoginBackground,
  },
  computed: {
    getActiveNotification() {
      return this.$store.getters['notifications/getActiveNotification'];
    },
  },
  async created() {
    await this.$store.dispatch('notifications/getActiveNotificationBeforeLogin');

    if (this.getActiveNotification.active && this.getActiveNotification.showBeforeLogin) {
      if (this.getActiveNotification.backgroundImage) {
        this.getActiveNotification.backgroundImage = await this.$store.dispatch('notifications/getImage');
      }

      const textMessageWithLinks = this.getActiveNotification.textMessage.replace(
        URL_MATCHER,
        '<a href="$1" target="_blank">$1</a>',
      );
      this.$store.dispatch('utils/_api_request_handler/show_notification_dialog', {
        title: this.getActiveNotification.headerText,
        subTitle: this.getActiveNotification.textMessage,
        backgroundImage: this.getActiveNotification.backgroundImage,
        textMessageWithLinks,
      });
    }
  },
};
</script>

<style scoped>
#page-wrapper {
  height: 100vh;
}
</style>
