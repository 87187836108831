<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2021. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->

<template>
  <div id="passwordField">
    <v-text-field
      :id="elementId"
      ref="password"
      v-model="inputValue"
      :append-icon="icon"
      :type="type"
      :label="label"
      :hint="hint"
      :required="required"
      :rules="allRules"
      :disabled="disabled"
      validate-on-blur
      @click:append="togglePassword"
      @input="emitValue"
    />
  </div>
</template>

<script>
export default {
  props: {
    elementId: {
      type: String,
      default: 'passwordFieldInput',
    },
    label: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
    required: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hidePassword: true,
      inputValue: this.value,
    };
  },
  computed: {
    type() {
      return this.hidePassword ? 'password' : 'text';
    },
    icon() {
      return this.hidePassword ? 'mdi-eye-off' : 'mdi-eye';
    },
    allRules() {
      return [
        ...this.rules,
        ...(this.required ? [(v) => !!v || v.length > 0 || this.$t('baseForm.passwordRequiredMsg')] : []),
        (v) =>
          (!this.required && v.length === 0) ||
          v.length >= this.min ||
          this.$t('baseForm.passwordMinLengthMsg', { msgLength: this.min }),
        ...(this.max > this.min
          ? [
              (v) =>
                (!this.required && v.length === 0) ||
                v.length <= this.max ||
                this.$t('baseForm.passwordMaxLengthMsg', { msgLength: this.max }),
            ]
          : []),
      ];
    },
  },
  watch: {
    value(newValue) {
      this.inputValue = newValue;
    },
  },
  methods: {
    togglePassword() {
      this.hidePassword = !this.hidePassword;
    },
    emitValue() {
      this.$emit('input', this.inputValue);
    },
  },
};
</script>
